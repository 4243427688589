exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-danke-tsx": () => import("./../../../src/pages/danke.tsx" /* webpackChunkName: "component---src-pages-danke-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kata-tsx": () => import("./../../../src/pages/kata.tsx" /* webpackChunkName: "component---src-pages-kata-tsx" */),
  "component---src-pages-vokabeln-tsx": () => import("./../../../src/pages/vokabeln.tsx" /* webpackChunkName: "component---src-pages-vokabeln-tsx" */),
  "component---src-templates-category-page-tsx": () => import("./../../../src/templates/CategoryPage.tsx" /* webpackChunkName: "component---src-templates-category-page-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/ProductPage.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-static-page-tsx": () => import("./../../../src/templates/StaticPage.tsx" /* webpackChunkName: "component---src-templates-static-page-tsx" */)
}

